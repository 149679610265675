.main-menu {
    height: 100%;
    position: fixed;
    width: 220px;
    background: linear-gradient(180deg, rgba(21, 20, 87, 0.58) 0%, #151457 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    z-index: 1001;
    .main-logo {
        max-height: 64px;
    }
    .navbar-nav > .nav-item > .nav-link {
        &.active {
            &:before {
                content: '';
                position: absolute;
                right: 0;
                border-right: 10px solid #fff;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
    }
}

@media (min-width: 786px) {
    .app-logged-in {
        margin-left: 220px;
    }
}

@media (max-width: 786px) {
    .navbar {
        background: linear-gradient(180deg, rgba(21, 20, 87, 0.58) 0%, #151457 100%);
        .navbar-brand, .navbar-nav .nav-item .nav-link {
            color: #fff;
        }
        .navbar-toggler {
            background: #fff;
        }
    }
}
